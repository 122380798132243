import { useState, useEffect, useRef, Fragment, useContext } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Logo from '../Logo/Logo'

import { PageTransitionContext } from '../../context/PageTransitionContext'
import { HomeContext } from '../../context/HomeContext'

const Navigation = () => {
  // context
  const pageTransitionContext = useContext(PageTransitionContext)
  const homeContext = useContext(HomeContext)

  // states
  const [isScrolling, setIsScrolling] = useState(false)
  const [innerOffsetLeft, setInnerOffsetLeft] = useState(undefined)
  const [innerHeight, setInnerHeight] = useState(undefined)
  const [expandedLogo, setExpandedLogo] = useState(pageTransitionContext.navigationIsOpen)

  // router
  const router = useRouter()

  // refs
  const NavigationInnerRef = useRef()
  const NavigationRef = useRef()
  const NavigationSubmenuHeader = useRef()
  const NavigationSubmenuInner = useRef()

  useEffect(() => {

    setTimeout( ()=> {
      // left offset of inner container. Logo uses this for centering at start of home intro animation
      setInnerOffsetLeft(NavigationInnerRef.current.offsetLeft)
      // menu height of inner container. Logo uses this for centering at start of home intro animation
      // ek - clamping to min value is temp solution for height sometimes being high number on production which causes the logo to not be positioned/shown correctly during intro
      setInnerHeight(Math.min(95, NavigationInnerRef.current.offsetHeight))
    }, 100)

    const lockEvent = () => {
      setIsScrolling(window.scrollY >= 10)
    }
    document.addEventListener('scroll', lockEvent)

    return () => {
      document.removeEventListener('scroll', lockEvent)
    }
  }, [])

  // check if navigation is open
  useEffect(() => {
    NavigationRef.current.classList.toggle('is-open', pageTransitionContext.navigationIsOpen)
  }, [pageTransitionContext.navigationIsOpen])

  // check if navigation is visible
  useEffect(() => {
    NavigationRef.current.classList.toggle('is-visible', homeContext.navigationIsVisible)
  }, [homeContext.navigationIsVisible])

  // scrolling event
  useEffect(() => {
    NavigationRef.current.classList.toggle('is-scrolling', isScrolling)
  }, [isScrolling])

  // This will trigger the status of navigation in context
  const triggerMenu = () => {
    setExpandedLogo(!pageTransitionContext.navigationIsOpen)
    pageTransitionContext.setNavigationIsOpen(!pageTransitionContext.navigationIsOpen)

    if(!pageTransitionContext.navigationIsOpen) {
      document.querySelector('body').style = `overflow: hidden`
    } else {
      document.querySelector('body').removeAttribute('style')
      setTimeout(() => {
        NavigationSubmenuInner.current.scrollTop = 0
      }, 300)
    }
  }

  const setActualVieportHeightUnits = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  useEffect(() => {
    setActualVieportHeightUnits()

    const resizeEventListener = () => {
      setActualVieportHeightUnits()
    }

    window.addEventListener('resize', resizeEventListener)
    return () => window.removeEventListener('resize', resizeEventListener)
  }, [])

  // Handles click when submenu is opened
  const handleOpenedMenuClick =  (e) => {

    // Get submenu left offset
    const rect = document.querySelector('.Navigation-submenuContent').getClientRects();
    const submenuContentOffsetLeft = rect[0].left

    // Don't close menu when submenu is clicked
    if (e.clientX > submenuContentOffsetLeft) {
      return false;
    }

    // Close menu
    triggerMenu()
  }

  return (
    <Fragment>
      <nav className="Navigation" ref={NavigationRef}>
         
         {/* <div className="Notification-banner">
            <div class="Notification-text">A NEW BEGINNING — As of March 25 2024, Benthem Crouwel Architects is situated at Verrijn Stuartweg 14, 1112 AX Diemen</div>
         </div> */}
        <div className="Navigation-inner" ref={NavigationInnerRef}>
          <div className="Navigation-item Navigation-logo">
            <Link href={`/`} as="/" prefetch={false}>
              <a className="Navigation-logoLink"
                 onMouseEnter={()=>{ setExpandedLogo(homeContext.logoReady && true) }}
                 onMouseLeave={()=>{ setExpandedLogo(homeContext.logoReady && false) }}
                 onClick={() => { if(pageTransitionContext.navigationIsOpen) triggerMenu()}}
              >
                <Logo containerOffset={innerOffsetLeft}
                      containerHeight={innerHeight}
                      isExpanded={expandedLogo}
                      showIntro={homeContext.showIntroLogo}/>
              </a>
            </Link>
          </div>

          <div className="Navigation-items">
            <div className={`Navigation-item ${router.asPath.includes(`/vision`) ? `is-active` : ``}`}>
              <Link href={`/vision`} as="/vision" prefetch={false}><a>Vision</a></Link>
            </div>
            <div className={`Navigation-item ${router.asPath.includes(`/projects`) ? `is-active` : ``}`}>
              <Link href={`/projects`} as="/projects" prefetch={false}><a>Projects</a></Link>
            </div>
            <div className={`Navigation-item ${router.asPath.includes(`/about`) ? `is-active` : ``}`}>
              <Link href={`/about`} as="/about" prefetch={false}><a>About</a></Link>
            </div>
            <div className={`Navigation-item ${router.asPath.includes(`/news`) ? `is-active` : ``}`}>
              <Link href={`/news`} as="/news" prefetch={false}><a>News</a></Link>
            </div>
            <button className="Navigation-item Navigation-trigger" onClick={() => triggerMenu()}>
              <svg className="Navigation-svg">
                <use xlinkHref="#icon-hamburger-menu"/>
              </svg>
            </button>
          </div>

          <div className="Navigation-submenu" onClick={(e) => handleOpenedMenuClick(e)}>
            <div className="Navigation-submenuInner" ref={NavigationSubmenuInner}>
              <div className="Navigation-submenuHead">
                <span className="Navigation-submenuLabel" ref={NavigationSubmenuHeader} />
                <button className="Navigation-submenuClose" onClick={() => triggerMenu()}>
                  <svg className="Navigation-submenuSvg">
                    <use xlinkHref="#icon-close"/>
                  </svg>
                </button>
              </div>
              <div className="Navigation-submenuContent">
                <div className="Navigation-submenuContentInner">
                  <ul className="Navigation-submenuMain">
                    <li className="Navigation-submenuItem"><Link href={`/vision`} as="/vision" prefetch={false}><a onClick={() => triggerMenu()}>Vision</a></Link></li>
                    <li className="Navigation-submenuItem"><Link href={`/projects`} as="/projects" prefetch={false}><a onClick={() => triggerMenu()}>Projects</a></Link></li>
                    <li className="Navigation-submenuItem"><Link href={`/about`} as="/about" prefetch={false}><a onClick={() => triggerMenu()}>About</a></Link></li>
                    <li className="Navigation-submenuItem"><Link href={`/news`} as="/news" prefetch={false}><a onClick={() => triggerMenu()}>News</a></Link></li>
                  </ul>
                  <ul className="Navigation-submenuSecondary">
                    <li className="Navigation-submenuItem"><Link href={`/services`} as="/services" prefetch={false}><a onClick={() => triggerMenu()}>Services</a></Link></li>
                    <li className="Navigation-submenuItem"><Link href={`/awards`} as="/awards"><a onClick={() => triggerMenu()}>Awards</a></Link></li>
                    <li className="Navigation-submenuItem"><Link href={`/[generic]`} as="/origins" prefetch={false}><a onClick={() => triggerMenu()}>Origins</a></Link></li>
                  </ul>
                  <ul className="Navigation-submenuSecondary">
                    <li className="Navigation-submenuItem"><Link href={`/people`} as="/people" prefetch={false}><a onClick={() => triggerMenu()}>People</a></Link></li>
                    <li className="Navigation-submenuItem"><a href={`https://benthem-crouwel-architects.homerun.co/`} target={`_blank`} onClick={() => triggerMenu()}>Careers</a></li>
                    <li className="Navigation-submenuItem"><Link href={`/[generic]`} as="/contact" prefetch={false}><a onClick={() => triggerMenu()}>Contact</a></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="Navigation-overlay"/>
        </div>
      </nav>
    </Fragment>
  )
}

export default Navigation
