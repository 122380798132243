import { useRef, useEffect, useState, useContext } from 'react'
import {gsap, Power3} from "gsap/dist/gsap"

const LOGO_WIDTH_CLOSED = 740
const LOGO_WIDTH_OPEN = 1135
const LOGO_OFFSET_CLOSED = 119
const LOGO_HEIGHT = 82

const GSAP_DURATION = 0.5
const GSAP_YOYO = true
const GSAP_REPEAT_DELAY = 0.15

import { HomeContext } from '../../context/HomeContext'
import { breakpoints } from '../../utils/breakPoints'

const Logo = (props) => {
  // props
  const { containerOffset, containerHeight, showIntro, isExpanded } = props

  // states
  const [logoFocus, setLogoFocus] = useState(!!isExpanded)
  const [initialLoad, setInitialLoad] = useState(true)

  // refs
  const logoRef = useRef()
  const partContainerOneRef = useRef()
  const partContainerFourRef = useRef()
  const partContainerFiveRef = useRef()
  const partContainerSixRef = useRef()
  const partOneRef = useRef()
  const partTwoRef = useRef()
  const partFiveRef = useRef()
  const partSixRef = useRef()
  const partContainerArchRef = useRef()
  const partArchRef = useRef()

  // context
  const homeContext = useContext(HomeContext)

  useEffect(() => {
    if (containerOffset !== undefined && containerHeight !== undefined){

      if (showIntro) {
        const getScale = () => {

          let scale;
          const windowWidth = window.innerWidth

          if (windowWidth <=  320){
            scale = 0.24
          }

          if (windowWidth > 320 && windowWidth <=  480){
            scale = 0.28
          }

          if (windowWidth > 480 && windowWidth <  breakpoints.medium){
            scale = 0.4
          }

          if (windowWidth >=  breakpoints.medium && windowWidth < breakpoints.large){
            scale = 0.5
          }

          if (windowWidth >=  breakpoints.large && windowWidth < breakpoints.xlarge){
            scale = 0.75
          }

          if (windowWidth >=  breakpoints.xlarge && windowWidth < breakpoints.xxlarge){
            scale = 0.91
          }

          if (windowWidth >=  breakpoints.xxlarge){
            scale = 1
          }

          return scale
        }

        //homeContext.setNavigationIsVisible(false)

        const logoScale = getScale()

        // offset x of menu because that needs be kept into account when positioning the logo in the center
        const menuOffsetX = Math.max(0, containerOffset + 30) // 30px is the padding difference

        // center logo in window
        const xPos = (window.innerWidth/2 - LOGO_OFFSET_CLOSED*logoScale) - ((LOGO_WIDTH_CLOSED*logoScale)/2) - menuOffsetX // 119 is offset B from closed to open, 370 is half width of closed
        const yPos = window.innerHeight/2 - (LOGO_HEIGHT*logoScale/2) - containerHeight/2
        gsap.set(logoRef.current, { x:xPos, y:yPos, scale: logoScale })

        const animateLogoIntro = () => {
          // initial fade in of logo on black
          gsap.to(logoRef.current, { duration:0.4, delay:0.5, opacity:1, onComplete:()=>{

              // x correction to make sure the logo stays centered
              gsap.to(logoRef.current, { duration:GSAP_DURATION, x:window.innerWidth/2 - (LOGO_WIDTH_OPEN*logoScale/2) - menuOffsetX, yoyo:GSAP_YOYO, repeat:1, repeatDelay:GSAP_REPEAT_DELAY, ease:Power3.easeInOut })

              // animate parts and part containers
              gsap.to(partContainerOneRef.current, { duration:GSAP_DURATION, x:0, yoyo:GSAP_YOYO, repeat:1, repeatDelay:GSAP_REPEAT_DELAY, ease:Power3.easeInOut})
              gsap.to(partContainerFourRef.current, { duration:GSAP_DURATION, x:33, yoyo:GSAP_YOYO, repeat:1, repeatDelay:GSAP_REPEAT_DELAY, ease:Power3.easeInOut})
              gsap.to(partContainerFiveRef.current, { duration:GSAP_DURATION, x:40, yoyo:GSAP_YOYO, repeat:1, repeatDelay:GSAP_REPEAT_DELAY, ease:Power3.easeInOut})
              gsap.to(partContainerSixRef.current, { duration:GSAP_DURATION, x:0, yoyo:GSAP_YOYO, repeat:1, repeatDelay:GSAP_REPEAT_DELAY, ease:Power3.easeInOut})
              gsap.to(partOneRef.current, { duration:GSAP_DURATION, x:0, yoyo:GSAP_YOYO, repeat:1, repeatDelay:GSAP_REPEAT_DELAY, ease:Power3.easeInOut})
              gsap.to(partTwoRef.current, { duration:GSAP_DURATION, x:0, yoyo:GSAP_YOYO, repeat:1, repeatDelay:GSAP_REPEAT_DELAY, ease:Power3.easeInOut})
              gsap.to(partFiveRef.current, { duration:GSAP_DURATION, x:0, yoyo:GSAP_YOYO, repeat:1, repeatDelay:GSAP_REPEAT_DELAY, ease:Power3.easeInOut})
              gsap.to(partSixRef.current, { delay:0.08, duration:GSAP_DURATION, x:0, yoyo:GSAP_YOYO, repeat:1, repeatDelay:GSAP_REPEAT_DELAY, ease:Power3.easeInOut, onComplete:()=>{

                  setTimeout(()=>{

                    // trigger title in Homeheader
                    homeContext.setShowTitle(true)

                    // trigger background video in BackgroundVideo
                    homeContext.setShowBackgroundVideo(true)

                    // show navigation after intro
                    homeContext.setNavigationIsVisible(true)
                  }, 200)

                  // scales the logo down and moves it to its position in the menu (-20 is offset between closed and open, -5 correction because navigation item fonts have some padding top)
                  gsap.to(logoRef.current, { duration:0.6, x:-20, y:0, scale:0.17, ease:Power3.easeInOut, onComplete:()=>{

                      homeContext.setLogoReady(true)

                    } })

                }})
            }
          })

        }

        animateLogoIntro()

      } else {

        // logo small in menu
        gsap.set(logoRef.current, { x:-20, y:0, scale:0.17 })
        gsap.to(logoRef.current, { duration:0.8, delay:0.5, opacity:1 })

        homeContext.setLogoReady(true)
      }
    }

    setInitialLoad(false)

  }, [containerOffset, containerHeight])

  // animation of logo when opening the about menu (benthem crouwel architects)
  useEffect(() =>{

    if (initialLoad) return

    const animateLogoMenu = ({logoRefx, cOneRefx, cFourRefx, cFiveRefx, cSixRefx, pOneRefx, pTwoRefx, pFiveRefx, pSixRefx, pArchRefx}) => {

      // x correction container
      gsap.to(logoRef.current, { duration:GSAP_DURATION, x:logoRefx, ease:Power3.easeInOut })

      // animate parts and part containers
      gsap.to(partContainerOneRef.current, { duration:GSAP_DURATION, x:cOneRefx, ease:Power3.easeInOut})
      gsap.to(partContainerFourRef.current, { duration:GSAP_DURATION, x:cFourRefx, ease:Power3.easeInOut})
      gsap.to(partContainerFiveRef.current, { duration:GSAP_DURATION, x:cFiveRefx, ease:Power3.easeInOut})
      gsap.to(partContainerSixRef.current, { duration:GSAP_DURATION, x:cSixRefx, ease:Power3.easeInOut})
      gsap.to(partOneRef.current, { duration:GSAP_DURATION, x:pOneRefx, ease:Power3.easeInOut})
      gsap.to(partTwoRef.current, { duration:GSAP_DURATION, x:pTwoRefx, ease:Power3.easeInOut})
      gsap.to(partFiveRef.current, { duration:GSAP_DURATION, x:pFiveRefx, ease:Power3.easeInOut})
      gsap.to(partSixRef.current, { delay:0.08, duration:GSAP_DURATION, x:pSixRefx, ease:Power3.easeInOut })
      gsap.to(partArchRef.current, { duration:GSAP_DURATION, x:pArchRefx, ease:Power3.easeInOut})
    }

    if (logoFocus){
      animateLogoMenu({logoRefx:0, cOneRefx:0, cFourRefx:33, cFiveRefx:40, cSixRefx:0, pOneRefx:0, pTwoRefx:0, pFiveRefx:0, pSixRefx:0, pArchRefx:0})
    } else {
      animateLogoMenu({logoRefx:-20, cOneRefx:60, cFourRefx:0, cFiveRefx:0, cSixRefx:-218, pOneRefx:59, pTwoRefx:60, pFiveRefx:-178, pSixRefx:-60, pArchRefx:-695})
    }

  }, [logoFocus])

  // Update logoFocus if property passed have changed
  useEffect(() => {
    setLogoFocus(isExpanded)
  }, [isExpanded])

  // collapse logo on resize
  useEffect(()=> {
    const checkExpand = () => {
      if(window.innerWidth <= 440 && logoFocus) {
        setLogoFocus(false)
      }
    }

    window.addEventListener('resize', checkExpand)
    checkExpand()

    return () => {
      window.removeEventListener('resize', checkExpand)
    }
  })

  // font size in Illustrator 120pt Gill Sans Regular
  return (
    <div className="Logo" ref={logoRef}>
      <div className="Logo-part-container one" ref={partContainerOneRef}><svg className="Logo-part one" ref={partOneRef}><use xlinkHref="#bca_logo_be"/></svg></div>
      <div className="Logo-part-container two"><svg className="Logo-part two" ref={partTwoRef}><use xlinkHref="#bca_logo_nthe"/></svg></div>
      <div className="Logo-part-container three"><svg className="Logo-part three"><use xlinkHref="#bca_logo_m"/></svg></div>
      <div className="Logo-part-container four" ref={partContainerFourRef}><svg className="Logo-part four"><use xlinkHref="#bca_logo_cr"/></svg></div>
      <div className="Logo-part-container five" ref={partContainerFiveRef}><svg className="Logo-part five" ref={partFiveRef}><use xlinkHref="#bca_logo_ouw"/></svg></div>
      <div className="Logo-part-container six" ref={partContainerSixRef}><svg className="Logo-part six" ref={partSixRef}><use xlinkHref="#bca_logo_el"/></svg></div>
      <div className="Logo-part-container arch" ref={partContainerArchRef}><svg className="Logo-part arch" ref={partArchRef}><use xlinkHref="#bca_logo_architects"/></svg></div>
    </div>
  )
}

export default Logo
