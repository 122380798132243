// This Context opens Modal component by using its reference id
import { createContext, useState } from 'react';

export const VideoModalContext = createContext()

const VideoModalProvider = (props) => {
  const [modal, setModal] = useState('')
  const [videoId, setVideoId] = useState('')
  const [videoChannel, setVideoChannel] = useState('')
  const [videoOpen, setVideoOpen] = useState(false)

  const closeVideoModal = () => {
    setVideoId('')
    setVideoOpen(false)
    document.body.style.overflow = '';
  }

  return (
    <VideoModalContext.Provider value={{modal, setModal, videoId, setVideoId, videoChannel, setVideoChannel, videoOpen, setVideoOpen, closeVideoModal}}>
      {props.children}
    </VideoModalContext.Provider>
  )
}

export default VideoModalProvider
