import * as Sentry from '@sentry/node'
import { RewriteFrames } from '@sentry/integrations'
import getConfig from 'next/config'

// Sentry.io
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig()
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next')
          return frame
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

// components
import Navigation from '../components/Navigation/Navigation'
import PageTransition from '../components/PageTransition/PageTransition'
import Seo from '../components/Seo/Seo'

// global styles
import '../styles/global.scss'

// import icons
import '../components/Svg'

// Import context for page transition
import PageTransitionProvider from '../context/PageTransitionContext'
import HomeContextProvider from '../context/HomeContext'
import VideoModalProvider from '../context/VideoModalContext'
import FilterContextProvider from '../context/FilterContext'
import Head from 'next/head'

function MyApp ({ Component, pageProps }) {
  return (
    <PageTransitionProvider>
      <HomeContextProvider>
        <VideoModalProvider>
          <FilterContextProvider>
            <Seo />
            <Head>
              {/* Default SEO properties that are not updated */}
              <link rel="apple-touch-icon" sizes="180x180" href="/static/favicons/apple-touch-icon.png"/>
              <link rel="icon" type="image/png" sizes="32x32" href="/static/favicons/favicon-32x32.png"/>
              <link rel="icon" type="image/png" sizes="16x16" href="/static/favicons/favicon-16x16.png"/>
              <link rel="manifest" href="/static/favicons/site.webmanifest"/>
              <link rel="mask-icon" href="/static/favicons/safari-pinned-tab.svg" color="#333333"/>
              <link rel="shortcut icon" href="/static/favicons/favicon.ico"/>
              <meta name="apple-mobile-web-app-title" content="Benthem Crouwel"/>
              <meta name="application-name" content="Benthem Crouwel"/>
              <meta name="msapplication-TileColor" content="#333333"/>
              <meta name="msapplication-TileImage" content="/static/favicons/mstile-144x144.png"/>
              <meta name="msapplication-config" content="/static/favicons/browserconfig.xml"/>
              <meta name="theme-color" content="#dddddd"/>
            </Head>
            <Navigation/>
            <PageTransition/>
            <Component {...pageProps} />
          </FilterContextProvider>
        </VideoModalProvider>
      </HomeContextProvider>
    </PageTransitionProvider>
  )
}

export default MyApp
