import React, { useEffect, useRef, useContext } from 'react'
import { gsap, Power2 } from 'gsap/dist/gsap'

// components
import AnimatedTitle from '../AnimatedTitle/AnimatedTitle'

// context
import { PageTransitionContext } from '../../context/PageTransitionContext'

const PageTransition = () => {

  // refs
  const PageTransitionRef = useRef()
  const PageTransitionTopRef = useRef()
  const PageTransitionBottomRef = useRef()
  const PageTransitionTitleContainerRef = useRef()

  // context
  const pageTransitionContext = useContext(PageTransitionContext)

  // page transition to vision has title, other transitions have no title so smaller delay for background animation
  let animationDelay = (pageTransitionContext.pageType === 'vision') ? 0.8 : 0.2

  // use effect for transition animation
  useEffect(() => {

    // initial position background fills
    gsap.set(PageTransitionTopRef.current, { y: '-100%' })
    gsap.set(PageTransitionBottomRef.current, { y: '100%' })

    // initial height title mask / container
    gsap.set(PageTransitionTitleContainerRef.current, { height: '50%' })

    // should I start with the animation?
    if (pageTransitionContext.pageTransition) {

      // lock scroll
      pageTransitionContext.setBodyLock(true)

      // show container
      PageTransitionRef.current.classList.add('PageTransition--show')

      // animate top half into screen
      gsap.to(PageTransitionTopRef.current, { duration: 0.6, y: 0, ease: Power2.easeOut, onComplete:()=>{

          // show underlying page
          pageTransitionContext.setShowPage(true)

          // animate title container mask out of screen
          gsap.to(PageTransitionTitleContainerRef.current, { delay:animationDelay, duration: 0.6, height: '0%', ease: Power2.easeInOut })

          // animate top half out of screen
          gsap.to(PageTransitionTopRef.current, { delay:animationDelay, duration: 0.6, y: '-100%', ease: Power2.easeInOut })

        } })

      // animate bottom half into screen
      gsap.to(PageTransitionBottomRef.current, { duration: 0.6, y: 0, ease: Power2.easeOut, onComplete:()=>{

          // animate bottom half out of screen
          gsap.to(PageTransitionBottomRef.current, {
            delay:animationDelay, duration: 0.6, y: '100%', ease: Power2.easeInOut, onComplete: () => {

              // hide container when done
              PageTransitionRef.current.classList.remove('PageTransition--show')

              // clean up transition
              pageTransitionContext.cleanUpTransition()
            }
          })

        } })

      return () => {
        gsap.killTweensOf(PageTransitionTopRef.current);
        gsap.killTweensOf(PageTransitionBottomRef.current);
        gsap.killTweensOf(PageTransitionTitleContainerRef.current);
      }

    }
  }, [pageTransitionContext.pageTransition])

  // use effect for setting the color modifier (for background)
  useEffect(() => {

    if (pageTransitionContext.style !== ''){
      PageTransitionRef.current.classList.add(`PageTransition--${pageTransitionContext.style}`)
    } else {
      PageTransitionRef.current.classList.remove(...PageTransitionRef.current.classList);
      PageTransitionRef.current.classList.add('PageTransition');
    }

  }, [pageTransitionContext.style])

  return (
    <div className="PageTransition" ref={PageTransitionRef}>
      <div className="PageTransition-top" ref={PageTransitionTopRef}/>
      <div className="PageTransition-bottom" ref={PageTransitionBottomRef}/>
      <div className="PageTransition-title" ref={PageTransitionTitleContainerRef}>

        {pageTransitionContext.animatedTitle && pageTransitionContext.pageType === 'vision' &&
        <AnimatedTitle title={pageTransitionContext.animatedTitle} page="pagetransition" startAnimation={true}/>
        }

      </div>
    </div>
  )
}

export default PageTransition
