// Manage state for filters on projects overview page
import { createContext, useState } from 'react'

export const FilterContext = createContext()

const FilterContextProvider = (props) => {
  const [status, setStatus] = useState(null)
  const [types, setTypes] = useState(null)
  const [regions, setRegions] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [openFilterAccordion, setOpenFilterAccordion] = useState(0)
  const [limit, setLimit] = useState(7)

  const selectFilter = (data) => {
    let newState = Array.from(selectedFilters)
    const selection = Object.assign({}, data)

    if(!selectedFilters.find((item) => item.id === selection.id)) {
      newState.push(selection)
      setSelectedFilters(newState)
    }
  }

  const removeFilter = (data) => {
    let newState = Array.from(selectedFilters)
    const selection = Object.assign({}, data)
    newState = newState.filter((obj) => obj.id !== selection.id)
    setSelectedFilters(newState)
  }

  const resetFilters = () => {
    let newState = []
    setSelectedFilters(newState)
  }

  return (
    <FilterContext.Provider value={{
      status,
      setStatus,
      types,
      setTypes,
      regions,
      setRegions,
      selectedFilters,
      setSelectedFilters,
      openFilterAccordion,
      setOpenFilterAccordion,
      selectFilter,
      removeFilter,
      resetFilters,
      limit,
      setLimit
    }}>
      {props.children}
    </FilterContext.Provider>
  )
}

export default FilterContextProvider
